define("bottomline-execution/app", ["exports", "@ember/application", "ember-data", "bottomline-execution/resolver", "ember-load-initializers", "bottomline-execution/config/environment", "@sentry/browser", "@sentry/integrations", "bottomline-execution/utils/guid-util"], function (_exports, _application, _emberData, _resolver, _emberLoadInitializers, _environment, Sentry, _integrations, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Sentry.init({
    dsn: _environment.default.SENTRY_DSN,
    integrations: [(0, _integrations.debugIntegration)(), (0, _integrations.rewriteFramesIntegration)({
      iteratee: function iteratee(frame) {
        frame.filename = '/' + frame.filename.split('/').pop();
        return frame;
      }
    })],
    release: '00.000.00',
    // This is replaced in the CircleCI build process
    beforeSend: function beforeSend(event, hint) {
      var breadcrumbs = event.breadcrumbs || [];
      var serverErrorPresent = breadcrumbs.get('lastObject.data.status_code') === 500;
      var requestAborted = hint.originalException.message === 'The adapter operation was aborted';
      var rejectError = _environment.default.environment === 'development' || !window.cordova || serverErrorPresent || requestAborted;

      if (rejectError) {
        console.log('%cError report for Sentry intercepted', 'color:red');
        Sentry.configureScope(function (scope) {
          return scope.clearBreadcrumbs();
        });
        return null;
      }

      return event;
    }
  });

  var App = _application.default.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  _emberData.default.Snapshot.prototype.adapterAction = function () {
    return (this.adapterOptions || {}).action;
  };

  _emberData.default.Snapshot.prototype.safeHasMany = function (models) {
    return this.hasMany(models) || [];
  };

  _emberData.default.Model.reopen({
    rollbackAttribute: function rollbackAttribute(attributeName) {
      var originalValue = this.changedAttributes(attributeName)[0];
      this.set(attributeName, originalValue);
    },
    createGuid: function createGuid() {
      return (0, _guidUtil.guidUtil)();
    }
  });

  var _default = App;
  _exports.default = _default;
});